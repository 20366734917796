* {
  font-family: 'Roboto', sans-serif;
}
html,body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}


/* carousel */
/* .carousel-wrapper {
  display: none;
} */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



/* Home:  wash-program  */
.wash-program-wrapper {
  margin-top: 30px;
}

.washprogram-header {
  margin-top: 20px;
  margin-bottom: 20px;
}
.wash-program {
  /* border : 1px solid rgb(225, 225, 225); */
  border-radius: 15px;
  box-shadow: 0 0 8px #000;
}
.washprogram-heading {
  font-size: 23px;
}
.washprogram-container {
  padding: 0 30px;
  position: relative;
}
.program-header {
  padding: 10px;
}
.program-heading {
  font-size: 18px;
}
.program-details{
  padding: 8px;
}
.program-details-ul {
  color : grey;
  margin-bottom: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  list-style: none;
}
.program-details-li {
  font-size: 15px;
  margin : 4px 0px;
}
.offer-applied {
  font-size: 15px;
  color: red;
  margin-left: 5px;
}
.offer-value {
  font-weight: 500;
  margin-top: 5px;
  font-size: 15px;
}
.program-sale-rate {
  font-weight: 500;
  font-size: 15px;
}
.program-rate {
  margin: 5px;
  font-size: 15px;
}
.program-btn-wrapper{
  margin-top: 16px;
  margin-bottom: 24px;
}
.program-btn {
  font-size : 15px;
}
.reviews-wrapper {
  margin-top: 30px;
}
.reviews-header {
  margin-top: 40px;
  margin-bottom: 20px;
}
.review-heading {
  font-size: 23px;
}
.reviews-cont {
  width: 98%;
  margin: 0 1%;
}
.user-review {
  border: 0;
  margin: 0;
}
.card-title {
  font-weight: 500;
  font-size: 14px;
}
.card-text {
  font-size: 14px;
}
.next-control-cont {
  position: absolute;
  height: 100px;
  width: 30px;
  top : calc((100% - 100px)/2);
  right:0;
  background-color: rgba(0,0,0,0.8);
  /* background-color: rgba(0,0,0,0.2); */
  border-radius : 3px;
}
.prev-control-cont {
  position: absolute;
  height: 100px;
  width: 30px;
  top : calc((100% - 100px)/2);
  left:0;
  background-color: rgba(0,0,0,0.8);
  /* background-color: rgba(0,0,0,0.2); */
  border-radius : 3px;
}
.social-handles-wrapper {
  padding : 0px 30px
}




/* stages  */
.accordion-button {
  font-size: 15px;
}
.add-address-inp {
  border : 1px solid grey;
}
.stage-confirm-btn {
  width: 25%;
}
.stage-cancel-btn {
  width: 25%;
}
.stage-accordian-wrapper {
  width: 75%;
}



/* overview */
.overview-cancel-btn {
  width: 25%;
}
.overview-confirm-btn {
  width: 25%;
}
.overview-loading-screen {
  min-height: calc(100vh - 86px);
}
.confirm-loading-screen{
  min-height: calc(100vh - 86px);
}
.simpleflex {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* signup */
.signup-wrapper {
  background-image: url(./assets/signup/signupbg.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left;
}
.signupbg-img{
  display: none;
}
.signup-inner-wrapper{
  width: 500px !important;
  margin : 0 !important;
  margin-right: 20px !important;
}


/* verification */
.verification-wrapper{
  min-height: calc(100vh - 86px);
  width: 500px !important;
  background-color: #fff;
}
.verification-loading-screen{
  min-height: calc(100vh - 86px);

}


/* forgot password */
.forgot-wrapper {
  min-height: calc(100vh - 86px);
  
}
.forgot-inner-wrapper,.forgot-verification{
  min-height: calc(100vh - 86px);
  width: 500px !important;
}
.forgot-loading-screen{
  min-height: calc(100vh - 86px);
}


/* login */
.login-inner-wrapper{
  min-height: calc(100vh - 86px);
  width: 500px !important;
  background-color: #fff;
  margin-right: 20px !important;
}
.loginbg{
  display: none;
}
.login-wrapper{
  background-image: url(./assets/login/loginbg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

/* profile */
.profile-submit-btn{
  width : 250px
}
.profile-wrapper{
  width: 500px;
  margin-right: 20px;
}
.profile-outer-wrapper{
  background-image: url(./assets/auth/profilebg.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  height: calc(100vh - 86px);
}
.profile-bg{
  display: none;
}
.profile-header{
  margin-bottom: 20px;
}
/* alert */
.alert-outer-wrapper {
  min-width: 330px;
}

/* address */
.address-outer-wrapper{
  width: 70%;
}
.edit-address-wrapper{
  background: url('./assets/address/address.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 500px;
}
.edit-address-outer-wrapper{
  width: 70%;
}
.edit-address-img-wrapper{
  display: none;
}
.add-new-address-outer-wrapper{
  width: 70%;
}
.add-new-address-wrapper{
  background: url('./assets/address/address.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 500px;
}
.add-new-address-img-wrapper{
  display: none;
}
/* wallet */
.wallet-outer-wrapper{
  width: 70%;
}

/* orders */
.order-wrapper{
  min-height: calc(100vh - 86px - 86px);
}
.order-outer-wrapper{
  width: 70%;
}
/* vieworder */
.vieworder-outer-wrapper{
  width: 70%;
}

/* paymentpage */
.paymentpage-outer-container{
  width: 70%;
}

/* contact */
.contact-container {
  background: url('./assets/contact/contactusbg.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: auto;
}
.contact-wrapper{
  width: 500px;
  margin-right: 20px;
}
/* refer */
.refer-outer-wrapper{
  width: 500px;
  background-color: #fff;
  margin-right: 20px;
}
.refer-container{
  background: url('./assets/refer/refer.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: auto;
}
.refer-img-wrapper{
  display: none;
}
/* spinner */
.spinner {
  
  border-radius: 50%;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
       spinner-oaa3wk 1.6s infinite linear;
}

.download-btn{
  transition: 0.3s;
}
.download-btn:hover{
  transform: scale(1.1);
}
.notfound-error-img{
  height: 80%;
}
.carousel-img{
  width: 60%;
}
@media (max-width : 900px){
  .carousel-img{
    width: 100%;
  }
}
@media (max-width : 500px) {
  .stage-confirm-btn,.stage-cancel-btn {
    width: 50%;
  }
  .refer-container{
    background: none;
  }
  .contact-wrapper {
    width: 100%;
    margin: 0;
  }
  .signup-inner-wrapper{
    width: 100% !important;
    margin: 0 !important;
  }
  .verification-wrapper{
    width: 100% !important;

  }
  .stage-accordian-wrapper {
    width: 100%;
  }
  .forgot-inner-wrapper,.forgot-verification{
    width: 100% !important;
  }
  .overview-confirm-btn,.overview-cancel-btn {
    width: 50%;
  }
  .signupbg-img {
    display: flex;
  }
  .login-inner-wrapper{
    min-height: calc(100vh - 86px);
    width: 100% !important;
    background-color: #fff;
    margin: 0 !important;
  }
  .alert-outer-wrapper {
    width: 100%;
  }
  .alert-wrapper{
    width: 96%;
  }
  .profile-submit-btn{
    width: 100%;
  }
  .profile-bg{
    display: flex;
  }
  .profile-wrapper {
    width: 100%;
    margin-right: 0;
  }
  .profile-header{
    margin-bottom: 0;
  }
  .order-outer-wrapper{
    width: 100%;
  }
  .vieworder-outer-wrapper{
    width: 100%;
  }
  .paymentpage-outer-container{
    width: 100%;
  }
  .wallet-outer-wrapper{
    width: 100%;
  }
  .address-outer-wrapper{
    width: 100%;
  }
  .edit-address-outer-wrapper{
    width: 100%;
  }
  .edit-address-img-wrapper{
    display: flex;
  }
  .add-new-address-outer-wrapper{
    width: 100%;
  }
  .add-new-address-img-wrapper{
    display: flex;
  }
  .refer-outer-wrapper{
    width: 100%;
    margin: 0;
  }
  .refer-img-wrapper{
    display: flex;
  }
  .loginbg{
    display: flex;
  }
  .notfound-error-img{
    width: 80%;
    height: unset;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@keyframes spinner-bulqg1 {
  0% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
     clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
     clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
     clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-oaa3wk {
  0% {
     transform: scaleY(1) rotate(0deg);
  }

  49.99% {
     transform: scaleY(1) rotate(135deg);
  }

  50% {
     transform: scaleY(-1) rotate(0deg);
  }

  100% {
     transform: scaleY(-1) rotate(-135deg);
  }
}